// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-ecommerce-photo-editing-js": () => import("./../../../src/pages/ecommerce-photo-editing.js" /* webpackChunkName: "component---src-pages-ecommerce-photo-editing-js" */),
  "component---src-pages-fashion-js": () => import("./../../../src/pages/fashion.js" /* webpackChunkName: "component---src-pages-fashion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewelry-js": () => import("./../../../src/pages/jewelry.js" /* webpackChunkName: "component---src-pages-jewelry-js" */),
  "component---src-pages-outsource-fashion-photo-editing-js": () => import("./../../../src/pages/outsource-fashion-photo-editing.js" /* webpackChunkName: "component---src-pages-outsource-fashion-photo-editing-js" */),
  "component---src-pages-outsource-jewelry-photo-editing-js": () => import("./../../../src/pages/outsource-jewelry-photo-editing.js" /* webpackChunkName: "component---src-pages-outsource-jewelry-photo-editing-js" */),
  "component---src-pages-outsource-portrait-photo-editing-js": () => import("./../../../src/pages/outsource-portrait-photo-editing.js" /* webpackChunkName: "component---src-pages-outsource-portrait-photo-editing-js" */),
  "component---src-pages-outsource-product-photo-editing-js": () => import("./../../../src/pages/outsource-product-photo-editing.js" /* webpackChunkName: "component---src-pages-outsource-product-photo-editing-js" */),
  "component---src-pages-outsource-real-estate-photo-editing-js": () => import("./../../../src/pages/outsource-real-estate-photo-editing.js" /* webpackChunkName: "component---src-pages-outsource-real-estate-photo-editing-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

